import { Helmet } from 'react-helmet-async';
import styled from 'styled-components';
import { useAllChains, useSimpleSearchParam, usePageParams, useScrollToTop } from 'hooks';
import { useActiveDapis, useChain } from 'hooks/chain-data';
import { breakpoints } from 'styles/theme';
import clsx from 'clsx';
import { ChainLogo } from '@api3/logos';
import { api3Chains } from '@api3/dapi-management';
import { allChainsByAlias, isDeprecatedChain } from 'utils/dapis';
import { useNotificationBannerContext } from 'components/layout/notification-banner';
import { DeprecatedBanner } from 'components/layout/deprecated-banner';
import { useEffect, useRef } from 'react';
import { fonts } from 'styles/fonts';
import { ChainPageToolbox } from './chain-page-toolbox';
import { ChainDApisSection } from './chain-dapis-section';
import { ChainPageFooter } from './chain-page-footer';

const ChainPage = () => {
  useScrollToTop();
  const { chainAlias } = usePageParams();
  const { chain } = useChain(chainAlias);
  const { setBanner } = useNotificationBannerContext();

  const { activeFeeds, isError, isLoading } = useActiveDapis(chain?.id);
  const { searchParamQueryValue } = useSimpleSearchParam();

  const { allChains } = useAllChains();
  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (isDeprecatedChain(chain?.id)) {
      setBanner({
        content: <DeprecatedBanner type="chain" />,
        type: 'deprecated',
      });
    }

    return () => {
      setBanner({ content: null, type: 'deprecated' });
    };
  }, [chain]);

  if (!chain || isLoading) {
    return null;
  }

  const { mainnetChain, options } = getDropdownOptions(chain.alias, chain.testnet, allChains);
  const isInSearchMode = !!searchParamQueryValue;

  return (
    <StyledWrapper>
      <Helmet>
        <title>API3 Market - {chain.name}</title>
      </Helmet>
      <div className="main-content">
        <ChainHeader className={clsx({ 'search-mode': isInSearchMode })}>
          <div className="chain-title">
            <img src={ChainLogo(mainnetChain.id)} alt={`Chain logo for ${mainnetChain.name}`} />
            <h1>
              We have 100+ data feeds{' '}
              <span className="chain-name">on&nbsp;{mainnetChain.name.replace(' ', `\u00A0`)}</span>
            </h1>
          </div>
        </ChainHeader>

        <ChainPageToolbox chain={chain} options={options} contentRef={contentRef} />
        <div className={clsx('content-wrapper', { 'search-mode': isInSearchMode })} ref={contentRef}>
          <ChainDApisSection activeFeeds={activeFeeds || []} isError={isError} isLoading={isLoading} />
          <ChainPageFooter
            isInSearchMode={isInSearchMode}
            hasActiveFeeds={!isLoading && !!activeFeeds && activeFeeds.length > 0}
          />
        </div>
      </div>
    </StyledWrapper>
  );
};

const getDropdownOptions = (chainAlias: string, testnet: boolean, allChains: api3Chains.Chain[]) => {
  const mainnetAlias = testnet
    ? chainAlias
        .replace('-holesky-testnet', '')
        .replace('-arbitrum-sepolia-testnet', '')
        .replace('-sepolia-testnet', '')
        .replace('-testnet', '')
    : chainAlias;
  const testnetAlias = `${mainnetAlias}-testnet`;
  const sepoliaTestnetAlias = `${mainnetAlias}-sepolia-testnet`;
  const arbitrumSepoliaTestnetAlias = `${mainnetAlias}-arbitrum-sepolia-testnet`;
  const holeskyTestnetAlias = `${mainnetAlias}-holesky-testnet`;

  const filterOptions = (chain: api3Chains.Chain) => {
    return (
      chain.alias === mainnetAlias ||
      chain.alias === testnetAlias ||
      chain.alias === sepoliaTestnetAlias ||
      chain.alias === holeskyTestnetAlias ||
      chain.alias === arbitrumSepoliaTestnetAlias
    );
  };

  // Get mainnet chain separately, as this is the one we display in the header of the page
  const mainnetChain = allChainsByAlias[mainnetAlias] || allChainsByAlias[chainAlias];
  // Get all options that match the chainAlias
  const options = allChains.filter(filterOptions);

  return {
    mainnetChain,
    options,
  };
};

const StyledWrapper = styled.div`
  margin-bottom: 64px;

  > .main-content {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    align-self: stretch;
    flex-direction: column;
    min-width: 200px;

    .chain-page-footer-background {
      height: 400px;
      bottom: 0;
      top: auto;
      overflow: visible;
    }
  }

  .content-wrapper {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    align-self: stretch;
    flex-direction: column;

    &.search-mode {
      // hide the scrolled content
      --clipped-content: calc(var(--content-clip-height) - 56px + var(--header-height-sm) * var(--header-displayed));
      clip-path: inset(var(--clipped-content) 0 0 0);

      // add fade effect but only when the page is scrolled
      --starting-opacity: calc(1 - var(--page-scrolled));
      -webkit-mask: linear-gradient(
        to bottom,
        rgba(0, 0, 0, var(--starting-opacity)) var(--clipped-content),
        rgba(0, 0, 0, 1) calc(var(--clipped-content) + 100px)
      );
      mask: linear-gradient(
        to bottom,
        rgba(0, 0, 0, var(--starting-opacity)) var(--clipped-content),
        rgba(0, 0, 0, 1) calc(var(--clipped-content) + 100px)
      );
    }
  }

  @media (min-width: ${breakpoints.sm}px) {
    > .main-content {
      .chain-page-footer-background {
        height: 800px;
      }
    }
  }

  @media (min-width: ${breakpoints.md}px) {
    .content-wrapper {
      &.search-mode {
        --clipped-content: calc(var(--content-clip-height) - 100px + var(--header-height-md) * var(--header-displayed));
      }
    }
  }
`;

const ChainHeader = styled.div`
  display: flex;
  align-self: center;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 58px;
  margin-top: 24px;
  height: 100%;
  width: 328px;
  transition: all var(--default-transition-duration);
  text-align: center;

  .chain-title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 6px;

    img {
      width: 48px;
      height: 48px;
    }

    h1 {
      ${fonts.heading[7]}
      margin: 0;

      .chain-name {
        color: var(--color-blue-25);
        font-family: 'Poppins', sans-serif;
      }
    }
  }

  @media (min-width: ${breakpoints.sm}px) {
    gap: 8px;
    margin-top: 16px;
    margin-bottom: 40px;
    width: 640px;

    .chain-title {
      img {
        width: 80px;
        height: 80px;
      }

      h1 {
        ${fonts.heading[5]}
        padding: 0 80px;
      }
    }
  }

  @media (min-width: ${breakpoints.md}px) {
    margin-top: 88px;
    margin-bottom: 96px;
    width: 1064px;

    .chain-title {
      gap: 0px;

      img {
        margin: 18px 0;
      }
      h1 {
        ${fonts.heading[3]}
        padding: 0;
      }
    }
  }

  &.search-mode {
    padding: 0;
    margin: 0;
    height: 0;
    overflow: hidden;

    * {
      display: none;
    }
  }

  @media (min-width: ${breakpoints.lg}px) {
    margin-top: 24px;
  }
`;

export default ChainPage;
